<div class="container" [class.active]="classApplied">
  <div class="navbar">
    <div class="menu">
      <h3 class="logo">2 Bears And A <span>Dog</span></h3>
      <div class="hamburger-menu" (click)="change()">
        <div class="bar"></div>
      </div>
    </div>
  </div>
  <div class="main-container">
    <div class="main">
      <header>
        <div class="overlay">
          <div class="inner">
            <h2 class="title">En Construction</h2>
            <p>Le site est actuellement en construction,
              il sera bientôt disponible .</p>
            <button class="btn">Rien de plus</button>
          </div>
        </div>
      </header>
    </div>
    <div class="shadow one"></div>
    <div class="shadow two"></div>
  </div>
  <div class="links">
    <ul>
      <li>
        <a href="#" style="--i: 0.05s">Acceuil</a>
      </li>
      <li>
        <a href="#" style="--i: 0.1s">Services</a>
      </li>
      <li>
        <a href="#" style="--i: 0.15s">Portofolio</a>
      </li>
      <li>
        <a routerLink="voyages" style="--i: 0.2s">Voyages</a>
      </li>
      <li>
        <a href="#" style="--i: 0.25s">A propos</a>
      </li>
      <li>
        <a href="#" style="--i: 0.3s">Contact</a>
      </li>
    </ul>
  </div>
</div>
