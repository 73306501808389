import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
public classApplied = false;
  constructor() { }

  ngOnInit(): void {
  }


  change() {
    this.classApplied = !this.classApplied;
  }
}
